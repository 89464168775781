
<template>
  <div>
    <!-- Dashboard Container -->
    <CScrollbar>
    <GridLayout
      :layout.sync="layout"
      :key="gridKey"
      :cols="{ lg: 12, md: 8, sm: 8, xs: 4, xxs: 4 }"
      :row-height="10"
      :is-draggable="true"
      :is-resizable="true"
      :responsive="true"
      :margin="[10, 10]"
    >
      <GridItem
        v-for="item in layout"
        :key="item.i"
        v-bind="{ ...item, accent: null }"
        :min-h="7"
        :min-w="2"
      >
        <RemovableDashboardCard v-if="item.view.data">
          <template #header>
            <CImg
              :src="item.icon"
              height="40px"
              style="max-height: 40px; max-width:100px"
              shape="rounded"
              alt="img/brand/coreui-base-white.svg" 
            />
            <span class="box-title">{{ item.static ? ` ${item.title}` : `${item.title}` }}</span>
              
          </template>
          <template #body>
            <component v-bind:is="item.view.type"  v-bind:data="item.view.data" :userData="item.view.data" class="h-80">

            </component>
            <!--GuageWidget /-->
          </template>
        </RemovableDashboardCard>
      </GridItem>
    </GridLayout>
    <CButton 
      class="ml-2 mx-1" 
      color="primary"
      @click="resetLayout('base')"
    >
      Restore to base layout
    </CButton>
    <CButton
      v-if="storedLayout"
      class="mx-2" 
      color="primary"
      @click="resetLayout"
    >
      Restore to layout saved in local storage
    </CButton>
    <CButton 
      class="mx-2" 
      color="primary" 
      @click="saveGrid"
    >
      Save grid structure to local storage
    </CButton>
    </CScrollbar>
  </div>
</template>

<style>

.box-title{
  text-align: center;
  font-size: 16px;
  float: center;
  width: 80%;
}
</style>



<script>


import DashboardCard from "./DashboardCard";
import RemovableDashboardCard from "./RemovableDashboardCard";
import { GridLayout, GridItem } from "vue-grid-layout";
import GaugeWrapper from "./GaugeWrapper";
//import GuageWidget from "../views/widgets/GaugeWidget";
import MapWrapper from "./MapWrapper";
import GrafanaGaugeWrapper from "../grafana/GrafanaGaugeWrapper";
import { latLng } from "leaflet";

const baseLayout = [
  {
    i: "0",
    x: 0,
    y: 0,
    w: 4,
    h: 16,
    accent: "primary",
    title: "Device Temperature",
    icon: "img/brand/STW-logo.png",
    view: {
      type: "GaugeWrapper",
      data: {
         value: 0,
         title:"",
         width: "100%",
            height: "80%",
            min: 0,
            max: 125,
            colorSteps: [], 
            gaugeSize: 2
      }
    },
  },
  {
    i: "1",
    x: 4,
    y: 0,
    w: 4,
    h: 12,
    accent: "secondary",
    static: false,
    title: "Pressure",
    icon: "img/brand/STW-logo.png",
    view: {
      type: "GrafanaGaugeWrapper",
      data: {
         value: 0,
         title:"",
         width: "100%",
            height: "80%",
            min: 0,
            max: 3000,
            colorSteps: [], 
            gaugeSize: 2
      }
    },
  },
  
  {
    i: "2",
    x: 8,
    y: 0,
    w: 4,
    h: 12,
    accent: "success",
    title: "Location",
    icon: "img/brand/coreui-base-white.svg",
    view: {
      type: "MapWrapper",
      data: {
          marker: [49.41322, -122.219482],
          center: [49.41322, -122.219482],
          zoom: 10,
      path: "/images/",
      //bounds:[],
       url: 	 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      //url: 	"https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png", // 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      },
    },
  },
];
const layoutKey = "Status-Draggable-Layouts";
let storedLayout = JSON.parse(localStorage[layoutKey] || null);

export default {
  name: "StatusDashboard",
  components: {
     

    GridLayout,
    GridItem,
    DashboardCard,
    RemovableDashboardCard,
   // GuageWidget,
    MapWrapper,
    GaugeWrapper,
    GrafanaGaugeWrapper 
  },
  props:{
      selected: Object,
  },
  data() {
    return {
      //selected: "Month",
      layout: storedLayout || baseLayout,
      storedLayout,
      gridKey: 0,
      timerHandle:undefined,
      myText: 1,
    };
  },
  mounted: function(){
      storedLayout = JSON.parse(localStorage[layoutKey] || null);

      this.layout[1].view.data.max = 3000;
      this.layout[0].view.data.max = 125;
  },
  created(){
      this.onStart(this);
  },
  destroyed(){
    if(this.timerHandle)
      clearInterval(this.timerHandle);
  },
  methods: {
    onStart(sender){
      
        this.timerHandle = setInterval(()=>{this.update(sender)},5000);
    },
    async update(sender){
      console.log(this.myText++);
      let item= this.selected;
      let param = {
          id: item.device,
          name: item.name,
          device: item.device,
          select: "last",
          truck: item.name, // TODO:Should Not Be Named truck
        };
        let temp = await this.$api.getSensorByID(item.name,param);
        if(temp.status ===200){
          //this.deviceStatus.
          try{
          var key="cpuTemp"; 

          this.layout[0].view.data.value = temp.data[0][key];
          this.layout[0].view.data.max = 125;
          this.layout[0].view.data.min = -40;
          key = "hose"
          this.layout[1].view.data.value = temp.data[0][key];
          this.layout[1].view.data.max = 3000;
          this.layout[1].view.data.min = 0;

          this.layout[2].view.data = this.selected;
          }catch(err){
              
          }
        }
          //this.layout[0].view.data.value = this.myText;
        //}
    },
    saveGrid() {
      this.storedLayout = JSON.parse(JSON.stringify(this.layout));
      localStorage.setItem(layoutKey, JSON.stringify(this.storedLayout));
    },
    resetLayout(type) {
      this.gridKey++;
      this.layout = type === "base" ? baseLayout : this.storedLayout;
    },
  },
};
</script>