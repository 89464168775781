var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CScrollbar",
        [
          _c(
            "GridLayout",
            {
              key: _vm.gridKey,
              attrs: {
                layout: _vm.layout,
                cols: { lg: 12, md: 8, sm: 8, xs: 4, xxs: 4 },
                "row-height": 10,
                "is-draggable": true,
                "is-resizable": true,
                responsive: true,
                margin: [10, 10]
              },
              on: {
                "update:layout": function($event) {
                  _vm.layout = $event
                }
              }
            },
            _vm._l(_vm.layout, function(item) {
              return _c(
                "GridItem",
                _vm._b(
                  { key: item.i, attrs: { "min-h": 7, "min-w": 2 } },
                  "GridItem",
                  Object.assign({}, item, { accent: null }),
                  false
                ),
                [
                  item.view.data
                    ? _c("RemovableDashboardCard", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function() {
                                return [
                                  _c("CImg", {
                                    staticStyle: {
                                      "max-height": "40px",
                                      "max-width": "100px"
                                    },
                                    attrs: {
                                      src: item.icon,
                                      height: "40px",
                                      shape: "rounded",
                                      alt: "img/brand/coreui-base-white.svg"
                                    }
                                  }),
                                  _c("span", { staticClass: "box-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        item.static
                                          ? " " + item.title
                                          : "" + item.title
                                      )
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "body",
                              fn: function() {
                                return [
                                  _c(item.view.type, {
                                    tag: "component",
                                    staticClass: "h-80",
                                    attrs: {
                                      data: item.view.data,
                                      userData: item.view.data
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            }),
            1
          ),
          _c(
            "CButton",
            {
              staticClass: "ml-2 mx-1",
              attrs: { color: "primary" },
              on: {
                click: function($event) {
                  return _vm.resetLayout("base")
                }
              }
            },
            [_vm._v(" Restore to base layout ")]
          ),
          _vm.storedLayout
            ? _c(
                "CButton",
                {
                  staticClass: "mx-2",
                  attrs: { color: "primary" },
                  on: { click: _vm.resetLayout }
                },
                [_vm._v(" Restore to layout saved in local storage ")]
              )
            : _vm._e(),
          _c(
            "CButton",
            {
              staticClass: "mx-2",
              attrs: { color: "primary" },
              on: { click: _vm.saveGrid }
            },
            [_vm._v(" Save grid structure to local storage ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }