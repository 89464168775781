

<template>
<CRow>
<CCol lg=12>
    
    <CEmbed
    style="map-container"
    type="iframe"
              :src="myUrl"
              width="90%"
              height="200"
              

    
    >
    </CEmbed>
</CCol>
</CRow>
</template>

<script>

/** A Wrapper around KGauge-Vue library by @kagronick/kgauge-vue
 * We might want the option to switch to just-gage-vue
 * So we made a wrapper component around the most relavent items 
 */

const defaultSettings = {
             value: 30,
            width: "100%",
            height: "100%",
            min: 0,
            max: 300,
            title: "",
            colorSteps: [], 
            gaugeSize: 2,
            precision: 2, 
        };

        
import KGauge from "@kagronick/kgauge-vue"

export default {
    name: "GrafanaGuageWrapper",
    components: {
        KGauge,
    },
    props:{
        //value: Number,
        data: {
            type: Object, 
            defaults: defaultSettings,
        }
    },
    data (){
        return {
            
        };
    },

    computed:{
        myUrl(){
                return "https://demo1.aarcomm.io/api/grafana/d-solo/a822APfMz/truck-717395-sensors-copy?orgId=1&panelId=2"; 
        }
    },
    methods: {

    }
}
</script>