var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { lg: "12" } },
        [
          _c("CEmbed", {
            staticStyle: {},
            attrs: {
              type: "iframe",
              src: _vm.myUrl,
              width: "90%",
              height: "200"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }